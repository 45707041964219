import { useDispatch } from 'react-redux';
import {
    setTemplatesRes, setTemplatesLoading, setTemplatesError,
} from '../redux/slices/templates';
import axios from '../utils/axiosConfig';
import { notify } from 'react-notify-toast';

const useProjectService = () => {
    const dispatch = useDispatch();

    const showError  = (error:any) =>{
        if(error?.response?.data?.error){
            notify.show(error.response.data.error, "error", 3000);
        }
        if(typeof error?.response?.data?.detail === "string" && error?.response?.data?.detail){
            notify.show(error.response.data.detail, "error", 3000);
        }
        if(error.message){
            notify.show(error.message, "error", 3000);
        }
        
    }

    const fetchAllTemplates = async (org_id?: any) => {
        dispatch(setTemplatesLoading(true));
        try {
            const response = await axios.get<any[]>(`/templates`);
            dispatch(setTemplatesRes(response?.data?.templates));
        } catch (error) {
            showError(error)
            dispatch(setTemplatesError(error));
        } finally {
            dispatch(setTemplatesLoading(false));
        }
    };
    const saveTemplate = async (payload:any) => {
        dispatch(setTemplatesLoading(true));
        try {
            const response = await axios.post<any[]>(`/template`,payload);
            notify.show(response.data.message, "success", 3000);
            await fetchAllTemplates ();
        } catch (error) {
            showError(error)
            dispatch(setTemplatesError(error));
        } finally {
            dispatch(setTemplatesLoading(false));
        }
    };
    const updateTemplate = async (payload:any) => {
        dispatch(setTemplatesLoading(true));
        try {
            const response = await axios.put<any[]>(`/template`,payload);
            notify.show(response.data.message, "success", 3000);
            await fetchAllTemplates ();
        } catch (error) {
            showError(error)
            dispatch(setTemplatesError(error));
        } finally {
            dispatch(setTemplatesLoading(false));
        }
    };

    const deleteTemplate = async (payload:any) => {
        dispatch(setTemplatesLoading(true));
        try {
            const response = await axios.delete<any[]>(`/template/${payload.template_id}`);
            notify.show(response.data.message, "success", 3000);
            await fetchAllTemplates ();
        } catch (error) {
            showError(error)
            dispatch(setTemplatesError(error));
        } finally {
            dispatch(setTemplatesLoading(false));
        }
    };

    return { 
        fetchAllTemplates,saveTemplate,updateTemplate,deleteTemplate
     };
};

export default useProjectService;