import { useSelector, useDispatch } from 'react-redux';
import { 
    setOrganizationData, setOrganizationLoading, setOrganizationError,
    setOrganizationSkillLoading,setOrganizationSkillError,setOrganizationSkillData,
    setOrganizationUpdateError,setOrganizationUpdateSuccess,setOrganizationUpdateLoading,setShowSkillEdit,
    setRecentProjectsLoading,setRecentProjectsData,setRecentProjectsError,setInviteUsersLoading,setInviteUsersError,setInviteUsersSuccess,
    setJiraSettingsGetSuccess,setJiraSettingsLoading,setJiraSettingsGetError,setJiraSettings,
    setJiraSettingsSaveSuccess,setJiraSettingsSaveError,setCreateOrganizationData,
    setProjectReferenceSaveError,setProjectReferenceLoading,projectReferenceRes,
    setOrganizationOnboardSettings,
    setAsanaSettingsGetSuccess,setAsanaSettingsLoading,setAsanaSettingsGetError,setAsanaSettings,
    setAsanaSettingsSaveSuccess,setAsanaSettingsSaveError,
    setOrgPreferencesError,setOrgPreferencesLoading,orgPreferencesRes,setOrgWorkingDays,
    setOrgWorkingDaysLoading,setAzureSettingsGetSuccess,setAzureSettingsLoading,setAzureSettingsGetError,setAzureSettings,
    setAzureSettingsSaveSuccess,setAzureSettingsSaveError,
} from '../redux/slices/organization';
import axios from '../utils/axiosConfig';
import { notify } from 'react-notify-toast';

interface Organization {
    org_id?: string;
    name: string;
    user_id: string;
    created_at?: Date;
    modified_at?: Date;
    business_type?: string;
    invite_user?: any;
}
interface UpdateOrganization {
    org_id?: string;
    current_role?: string;
    department?: string;
    team_size?: string;
    company_size?: string;
    seniority?: string;
}

const useOrganizationService = () => {
    const { data } = useSelector((state: any) => state.organization);
    const dispatch = useDispatch();

    const showError  = (error:any) =>{
        if(error?.response?.data?.error){
            // toast.error(error.response.data.error);
            notify.show(error.response.data.error, "error", 3000);
        }
        if(typeof error?.response?.data?.detail === "string" && error?.response?.data?.detail){
            // toast.error(error.response.data.detail);
            notify.show(error.response.data.detail, "error", 3000);
        }
        if(error.message){
            notify.show(error.message, "error", 3000);
        }
        
    }

    const fetchOrganization = async (org_id: any) => {
        dispatch(setOrganizationLoading(true));
        try {
            const response:any = await axios.get<Organization[]>(`/organization/${org_id}`);
            // dispatch(setOrganizationData(response.data));
            if(response?.data?.onboard_settings && response?.data?.onboard_settings !== null){
                dispatch(setOrganizationOnboardSettings(response.data.onboard_settings));
            } else {
                dispatch(setOrganizationOnboardSettings({}));
            }
            
        } catch (error) {
            showError(error)
            dispatch(setOrganizationError(error));
        } finally {
            dispatch(setOrganizationLoading(false));
        }
    };

    const fetchAllProjects = async (org_id: string) => {
        dispatch(setOrganizationLoading(true));
        try {
            const response = await axios.get<Organization[]>(`/organization/${org_id}/projects`);
            console.log("response  ",response);
            
            dispatch(setOrganizationData(response.data));
        } catch (error) {
            showError(error)
            dispatch(setOrganizationError(error));
        } finally {
            dispatch(setOrganizationLoading(false));
        }
    };

    const fetchRecentProjects = async (org_id: string) => {
        dispatch(setRecentProjectsLoading(true));
        try {
            const response = await axios.get<Organization[]>(`/organization/${org_id}/recentprojects`);
            dispatch(setRecentProjectsData(response.data));
        } catch (error) {
            showError(error)
            dispatch(setRecentProjectsError(error));
        } finally {
            dispatch(setRecentProjectsLoading(false));
        }
    };

    const createOrg = async (itemData: Organization) => {
        dispatch(setOrganizationLoading(true));
        try {
            const response = await axios.post<Organization>(`/organization`, itemData,{headers: { "Content-Type": "multipart/form-data" }});
            notify.show(response.data.message, "success", 3000);
            dispatch(setCreateOrganizationData(response.data));
            dispatch(setOrganizationUpdateSuccess(true));
        } catch (error) {
            showError(error)
            dispatch(setOrganizationError(error));
        } finally {
            dispatch(setOrganizationLoading(false));
        }
    };

    const updateOrganization = async (orgId: number, itemData: any,isfetchOrgData=false) => {
        dispatch(setOrganizationUpdateSuccess(false));
        dispatch(setOrganizationUpdateLoading(true));
        try {
             const response = await axios.put<UpdateOrganization>(`/organization/${orgId}`, itemData);
             if(isfetchOrgData){
                await fetchOrganization(orgId)
             } else {
                notify.show(response.data.message, "success", 3000);
             }
            dispatch(setOrganizationUpdateSuccess(true));
        } catch (error) {
            showError(error)
            dispatch(setOrganizationUpdateError(error));
        } finally {
            dispatch(setOrganizationUpdateLoading(false));
        }
    };

    const deleteItem = async (itemId: number) => {
        dispatch(setOrganizationLoading(true));
        try {
            await axios.delete<void>(`/${itemId}`);
            const updatedData = data.filter((item: any) => item.org_id !== itemId);
            dispatch(setOrganizationData(updatedData));
        } catch (error) {
            showError(error)
            dispatch(setOrganizationError(error));
        } finally {
            dispatch(setOrganizationLoading(false));
        }
    };

    const fetchOrganizationSkill = async (org_id: string) => {
        dispatch(setOrganizationSkillLoading(true));
        try {
            const response = await axios.get<Organization[]>(`/organization/${org_id}/skills`);
            dispatch(setOrganizationSkillData(response.data));
        } catch (error) {
            showError(error)
            dispatch(setOrganizationSkillError(error));
        } finally {
            dispatch(setOrganizationSkillLoading(false));
        }
    };

    const deleteOrganizationSkill = async (org_id: string,skill_id:string) => {
        dispatch(setOrganizationSkillLoading(true));
        try {
            const response = await axios.delete<any>(`/organization/${org_id}/skill/${skill_id}`);
            notify.show(response.data.message, "success", 3000);
            fetchOrganizationSkill(org_id)
            dispatch(setShowSkillEdit(false));
        } catch (error) {
            showError(error)
            dispatch(setOrganizationSkillError(error));
        } finally {
            dispatch(setOrganizationSkillLoading(false));
            dispatch(setShowSkillEdit(false));
        }
    };


    const addSkills = async (org_id:string,itemData: any) => {
        dispatch(setOrganizationSkillLoading(true));
        try {
            const response = await axios.post<Organization>(`/organization/${org_id}/skill`, itemData);
            notify.show(response.data.message, "success", 3000);
            fetchOrganizationSkill(org_id)
            dispatch(setShowSkillEdit(false));
        } catch (error) {
            showError(error)
            dispatch(setOrganizationSkillError(error));
        } finally {
            dispatch(setOrganizationSkillLoading(false));
        }
    };
    const inviteUsers = async (org_id:string,itemData: any) => {
        dispatch(setInviteUsersSuccess(false));
        dispatch(setInviteUsersLoading(true));
        try {
            const response = await axios.post<Organization>(`/organization/${org_id}/invite-user`, itemData);
            notify.show(response.data.message, "success", 3000);
            dispatch(setInviteUsersSuccess(true));
        } catch (error) {
            showError(error)
            dispatch(setInviteUsersError(error));
        } finally {
            dispatch(setInviteUsersLoading(false));
        }
    };

    const updateSkills = async (org_id: string, itemData: any) => {
        dispatch(setOrganizationUpdateSuccess(false));
        dispatch(setOrganizationLoading(true));
        try {
             const response = await axios.put<UpdateOrganization>(`/organization/${org_id}/skill/${itemData.id}`, itemData);
             notify.show(response.data.message, "success", 3000);
             fetchOrganizationSkill(org_id)
             dispatch(setShowSkillEdit(false));
        } catch (error) {
            showError(error)
            dispatch(setOrganizationUpdateError(error));
        } finally {
            dispatch(setOrganizationLoading(false));
        }
    };

    const getJiraSettings = async (org_id: string, itemData: any) => {
        dispatch(setJiraSettingsGetSuccess(false));
        dispatch(setJiraSettingsLoading(true));
        try {
             const response = await axios.get<UpdateOrganization>(`/organization/${org_id}/jira_integration_settings`, itemData);
             dispatch(setJiraSettings(response.data));
        } catch (error) {
            showError(error)
            dispatch(setJiraSettingsGetError(error));
        } finally {
            dispatch(setJiraSettingsLoading(false));
        }
    };

    const saveJiraSettings = async (org_id: string, itemData: any) => {
        dispatch(setJiraSettingsSaveSuccess(false));
        dispatch(setJiraSettingsLoading(true));
        try {
             const response = await axios.post<any>(`/organization/${org_id}/jira_integration_settings`, itemData);
             notify.show(response.data.message, "success", 3000);
             await getJiraSettings(org_id,itemData)
        } catch (error) {
            showError(error)
            dispatch(setJiraSettingsSaveError(error));
        } finally {
            dispatch(setJiraSettingsLoading(false));
        }
    };

    const getAsanaSettings = async (org_id: string, itemData: any) => {
        dispatch(setAsanaSettingsGetSuccess(false));
        dispatch(setAsanaSettingsLoading(true));
        try {
             const response = await axios.get<UpdateOrganization>(`/organization/${org_id}/asana_integration_settings`, itemData);
             dispatch(setAsanaSettings(response.data));
        } catch (error) {
            showError(error)
            dispatch(setAsanaSettingsGetError(error));
        } finally {
            dispatch(setAsanaSettingsLoading(false));
        }
    };

    const saveAsanaSettings = async (org_id: string, itemData: any) => {
        dispatch(setAsanaSettingsSaveSuccess(false));
        dispatch(setAsanaSettingsLoading(true));
        try {
             const response = await axios.post<any>(`/organization/${org_id}/asana_integration_settings`, itemData);
             notify.show(response.data.message, "success", 3000);
        } catch (error) {
            showError(error)
            dispatch(setAsanaSettingsSaveError(error));
        } finally {
            dispatch(setAsanaSettingsLoading(false));
        }
    };

    const saveProjectReference = async (org_id: string, itemData: any) => {
        dispatch(setProjectReferenceLoading(true));
        try {
             const response = await axios.post<any>(`/organization/${org_id}/project_reference`, itemData);
             notify.show(response.data.message, "success", 3000);
        } catch (error) {
            showError(error)
            dispatch(setProjectReferenceSaveError(error));
        } finally {
            dispatch(setProjectReferenceLoading(false));
        }
    };

    const getProjectReference = async (org_id: string, itemData: any) => {
        dispatch(setProjectReferenceLoading(true));
        try {
             const response = await axios.get<UpdateOrganization>(`/organization/${org_id}/project_reference`, itemData);
             dispatch(projectReferenceRes(response.data));
        } catch (error) {
            showError(error)
            dispatch(setProjectReferenceSaveError(error));
        } finally {
            dispatch(setProjectReferenceLoading(false));
        }
    };

    const getOrgPreferences = async (org_id: string) => {
        dispatch(setOrgPreferencesLoading(true));
        try {
             const response = await axios.get<any>(`/organization/${org_id}/preferences`);
             dispatch(orgPreferencesRes(response.data));
        } catch (error) {
            showError(error)
            dispatch(setOrgPreferencesError(error));
        } finally {
            dispatch(setOrgPreferencesLoading(false));
        }
    };

    const deleteAllOrgPreferences = async (org_id: string) => {
        dispatch(setOrgPreferencesLoading(true));
        try {
                await axios.delete<any>(`/organization/${org_id}/preferences`);
                await getOrgPreferences(org_id)
        } catch (error) {
            showError(error)
            dispatch(setOrgPreferencesError(error));
        } finally {
            dispatch(setOrgPreferencesLoading(false));
        }
    };
    const deleteSingleOrgPreferences = async (org_id: string,pref_id:any) => {
        dispatch(setOrgPreferencesLoading(true));
        try {
                await axios.delete<any>(`/organization/${org_id}/preferences/${pref_id}`);
                await getOrgPreferences(org_id)
        } catch (error) {
            showError(error)
            dispatch(setOrgPreferencesError(error));
        } finally {
            dispatch(setOrgPreferencesLoading(false));
        }
    };

    const saveOrgPreferences = async (org_id: string, itemData: any) => {
        dispatch(setOrgPreferencesLoading(true));
        try {
             const response = await axios.post<any>(`/organization/${org_id}/preference`, itemData);
             notify.show(response.data.message, "success", 3000);
             await getOrgPreferences(org_id)
        } catch (error) {
            showError(error)
            dispatch(setOrgPreferencesError(error));
        } finally {
            dispatch(setOrgPreferencesLoading(false));
        }
    };

    const fetchOrgWorkingDays = async (org_id: any) => {
        dispatch(setOrgWorkingDaysLoading(true));
        try {
            const response:any = await axios.get<any[]>(`/organization/${org_id}/working_days`);
            dispatch(setOrgWorkingDays(response?.data?.working_days));
        } catch (error) {
            showError(error)
        } finally {
            dispatch(setOrgWorkingDaysLoading(false));
        }
    };

    const saveAzureSettings = async (org_id: string, itemData: any) => {
        dispatch(setAzureSettingsSaveSuccess(false));
        dispatch(setAzureSettingsLoading(true));
        try {
             const response = await axios.post<any>(`/integration/azure_settings`, itemData);
             notify.show(response.data.message, "success", 3000);
             await getAzureSettings(org_id);
        } catch (error) {
            showError(error)
            dispatch(setAzureSettingsSaveError(error));
        } finally {
            dispatch(setAzureSettingsLoading(false));
        }
    };
    const getAzureSettings = async (org_id: string, itemData?: any) => {
        dispatch(setAzureSettingsGetSuccess(false));
        dispatch(setAzureSettingsLoading(true));
        try {
             const response = await axios.get<UpdateOrganization>(`/integration/azure_settings`, itemData);
             dispatch(setAzureSettings(response.data));
        } catch (error) {
            showError(error)
            dispatch(setAzureSettingsGetError(error));
        } finally {
            dispatch(setAzureSettingsLoading(false));
        }
    };

    return { 
        fetchOrganization, fetchAllProjects, createOrg, 
        updateOrganization, deleteItem,fetchOrganizationSkill,
        addSkills, updateSkills,fetchRecentProjects,inviteUsers,
        saveJiraSettings,getJiraSettings,saveProjectReference,getProjectReference,
        getAsanaSettings,saveAsanaSettings,deleteOrganizationSkill,
        getOrgPreferences,deleteAllOrgPreferences,saveOrgPreferences,deleteSingleOrgPreferences,
        fetchOrgWorkingDays,saveAzureSettings,getAzureSettings
    };
};

export default useOrganizationService;