import { useDispatch } from 'react-redux';
import axios from '../utils/axiosConfig';
import { 
    setChatMessages, 
    setChatMessagesError, 
    setChatMessagesLoading, 
    setChatSubquestions, 
    setChatSubquestionsError, 
    setChatSubquestionsLoading,
    setChatLastActiveQuestionsLoading,
    setLastActiveQuestions,
    setLastActiveChatId,
    setChatLastActiveQuestionsError, 
    setChatHistoryList, 
    setChatHistoryListError, 
    setChatHistoryListLoading,
    setChatMessageById, 
    setChatMessageByIdError, 
    setChatMessageByIdLoading,
    setNewChatIdLoading
} from '../redux/slices/chat';
import { notify } from 'react-notify-toast';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
axios.interceptors.request.use(async function (config:any) {
    let  token = ''
    if(Clerk?.session ){
        token =  await Clerk.session.getToken({template:"main"});
    }
    config.headers.Authorization = `Bearer ${token}`
    return config;
});
axios.defaults.timeout = 60000;



const useChatService = () => {
    const dispatch = useDispatch();

    const handleChatQuery = async (body: any) => {
        dispatch(setChatMessagesLoading(true));
        try {
            const response = await axios.post<any>(`${API_BASE_URL}/handle-query`,body);
            dispatch(setChatMessages(response.data));
        } catch (error) {
            dispatch(setChatMessagesError(error));
        } finally {
            dispatch(setChatMessagesLoading(false));
        }
    };

    const handleChatSubquestions = async (body: any) => {
        dispatch(setChatSubquestionsLoading(true));
        try {
            const response = await axios.post<any>(`${API_BASE_URL}/handle-subquestions`,body);
            dispatch(setChatSubquestions(response?.data?.sub_questions));
        } catch (error) {
            dispatch(setChatSubquestionsError(error));
        } finally {
            dispatch(setChatSubquestionsLoading(false));
        }
    };
    const getLastActiveQuestions = async (project_id: any) => {
        // dispatch(setChatLastActiveQuestionsLoading(true));
        try {
            const response = await axios.get<any>(`${API_BASE_URL}/chat/project/${project_id}/lastActiveChat`);
            // dispatch(setLastActiveQuestions(response?.data?.data));
            dispatch(setLastActiveChatId(response?.data?.chat_id));
        } catch (error) {
            // dispatch(setChatLastActiveQuestionsError(error));
        } finally {
            // dispatch(setChatLastActiveQuestionsLoading(false));
        }
    };
    const getChatHistoryList = async (project_id: any) => {
        dispatch(setChatHistoryListLoading(true));
        try {
            const response = await axios.get<any>(`${API_BASE_URL}/chat/project/${project_id}`);
            dispatch(setChatHistoryList(response?.data));
        } catch (error) {
            dispatch(setChatHistoryListError(error));
        } finally {
            dispatch(setChatHistoryListLoading(false));
        }
    };
    const getChatMessageById = async (chat_id: any) => {
        dispatch(setChatMessageByIdLoading(true));
        try {
            const response = await axios.get<any>(`${API_BASE_URL}/chat/${chat_id}`);
            dispatch(setChatMessageById(response?.data));
        } catch (error) {
            dispatch(setChatMessageByIdError(error));
        } finally {
            dispatch(setChatMessageByIdLoading(false));
        }
    };
    const getNewChatId = async (body: any) => {
        dispatch(setNewChatIdLoading(true));
        try {
            const response = await axios.post<any>(`${API_BASE_URL}/newchat`,body);
            dispatch(setLastActiveChatId(response?.data));
        } catch (error) {
            // dispatch(setChatSubquestionsError(error));
        } finally {
            dispatch(setNewChatIdLoading(false));
        }
    }
    const deleteChatMessageById = async (chat_id: any,project_id:string) => {
        dispatch(setChatMessageByIdLoading(true));
        try {
            const response = await axios.delete<any>(`${API_BASE_URL}/chat/${chat_id}`);
            await getChatHistoryList(project_id)
            notify.show(response.data.message, "success", 3000);
        } catch (error) {
            dispatch(setChatMessageByIdError(error));
        } finally {
            dispatch(setChatMessageByIdLoading(false));
        }
    };

    const saveChatTitleById = async (chat_id: any,payload:any) => {
        dispatch(setChatMessageByIdLoading(true));
        try {
            const response = await axios.put<any>(`${API_BASE_URL}/chat/${chat_id}`,payload);
            notify.show(response.data.message, "success", 3000);
        } catch (error) {
            dispatch(setChatMessageByIdError(error));
        } finally {
            dispatch(setChatMessageByIdLoading(false));
        }
    };

    return { 
            handleChatQuery,
            handleChatSubquestions,
            getLastActiveQuestions,
            getChatHistoryList,
            getChatMessageById,
            getNewChatId, 
            deleteChatMessageById,
            saveChatTitleById 
        };
};

export default useChatService;